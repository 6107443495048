import { useState, CSSProperties, useEffect } from 'react';
import { useNavigate, useLoaderData, Navigate, useSearchParams, Link } from 'react-router-dom';
import { Avatar, Button, Card, Col, Divider, Dropdown, DropDownProps, Form, Input, message, Modal, Result, Row, Select, Space, Switch, Tabs, Tooltip, Typography, Upload, UploadProps } from 'antd';
import {
  AlipayOutlined,
  AppleOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CodeFilled,
  CopyFilled,
  DownOutlined,
  FacebookOutlined,
  GoogleOutlined,
  LockOutlined,
  MessageOutlined,
  MobileOutlined,
  SmileFilled,
  SmileOutlined,
  TaobaoOutlined,
  UploadOutlined,
  UserOutlined,
  WeiboOutlined,
} from '@ant-design/icons';
import AntColorButton from 'antd-button-color';
import { LoginForm, ProFormCaptcha, ProFormCheckbox, ProFormText } from '@ant-design/pro-components';
import { store, useAppDispatch, useAppSelector } from '../store';
import { selectAuthState, loginByUserPassThunk, getUserInfoThunkAction, setUserProfile } from '../reduxs/authSlice';
import { selectLanuageState, selectThemeState } from '../reduxs/themeSlice';
import { useTranslation } from 'react-i18next';
import { loginGoogleApi } from '../apis/authApi';
import Loading, { LoadingComponent } from '../components/loading';
import Paragraph from 'antd/lib/skeleton/Paragraph';
import { GPT_MODEL_3_5, GPT_MODEL_3_5_TURBO, GPT_MODEL_4, GPT_MODEL_4_TURBO, LANGUAGES, RAG_STRATEGY } from '../consts/constApp';
import { getCurrentBot, keyToTitle, setCurrentBot } from '../utils/appHelper';
import { getAllBotApi, getAxiosForUploadImage, getBotByIdApi, getProviderApi, updateBotApi } from '../apis/appApi';
import { MenuProps } from 'antd/lib';
import { BotDataType } from '../types/appTypes';
import CurrentBotComponent from '../components/currentBot/currentBotComponent';
import { selectAppState, setCurrentBotReduxAction } from '../reduxs/appSlice';
import PreviewWebChatComponent from '../components/previewWebChat/previewWebChatComponent';
import { apiClient } from '../apis/axiosBase';
// import CodeBlock from 'react-code-block';
// import { useCopyToClipboard } from 'react-use';

const BotInstanceWidgetPage = ({ isCreateNew, createdData, onNext, onPrev }: any = null) => {
  const { t, i18n } = useTranslation();
  const userInfo = useLoaderData();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuthState);
  const appState = useAppSelector(selectAppState);
  const themeState = useAppSelector(selectThemeState);
  const lang = useAppSelector(selectLanuageState);
  const [searchParams, setSearchParams] = useSearchParams();
  const [botItems, setBotItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpenModalGetScript, setisOpenModalGetScript] = useState(false);
  const [providers, setProviders] = useState<any>([]);
  const [currentProvider, setCurrentProvider] = useState<any>(null);
  const [botData, setBotData]: [BotDataType, any] = useState(isCreateNew ? createdData : null);
  const propsUploadAvatar: UploadProps = {
    onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        // message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    showUploadList: false,
    customRequest: (options: any) => {
      const { onSuccess, onError, file, onProgress } = options;
      // console.log(`file `, file);
      const fmData = new FormData();
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: (event: any) => {
          console.log((event.loaded / event.total) * 100);
          // onProgress({ percent: (event.loaded / event.total) * 100 },file);
        },
      };
      fmData.append('files', file);
      getAxiosForUploadImage({
        formData: fmData,
        config,
      })
        .then(async (res: any) => {
          onSuccess(file);
          let avatar = res.data.data.resourceUrls[0];
          console.log(res, avatar);
          try {
            // await updateUserProfileApi(authState?.user?.id, { avatar: avatar });
            message.success(`Upload avatar success`);
            let refreshBot = {
              ...botData,
              widgetIcon: avatar,
            };

            setBotData({
              ...refreshBot,
            });
            if (!isCreateNew) {
              // setCurrentBot({
              //   ...refreshBot
              // });
              // dispatch(setCurrentBotReduxAction(refreshBot));
            }
          } catch (error) {
            console.error(error);
            message.error(JSON.stringify(error));
          }
        })
        .catch((error: any) => {
          // const error = new Error('Some error');
          // onError({event:error});
          console.error(error);
          message.error(JSON.stringify(error));
        });
    },
  };

  const setBotDataCustom = (data: any) => {
    if (data) {
      setBotData(data);
      for (let k in data) {
        form.setFieldValue(k, data[k]);
      }
    }
  };
  const initFn: any = async () => {
    setLoading(true);
    const [providerItems, result, botInfo] = await Promise.all([getProviderApi(), getAllBotApi(), appState.currentBotInstance?.id ? getBotByIdApi({ id: appState.currentBotInstance?.id }) : null]);
    let allBotItems = result.items;

    setProviders(providerItems);
    setCurrentProvider(providerItems.find((r: any) => r.llmProvider == botData?.llmProvider));
    setBotItems(allBotItems as any);
    if (isCreateNew) {
      setBotDataCustom(createdData);
    } else if (!botInfo) {
      setBotDataCustom(allBotItems[0]);
    } else if (botInfo) {
      // console.log(`has redux state `,appState.currentBotInstance, botInfo);
      setBotDataCustom(botInfo);
    }
    setLoading(false);
  };
  useEffect(() => {
    initFn();
  }, [appState.currentBotInstance, isCreateNew, createdData]);

  const getscriptCode = (appId: string) => {
    const domainWiget = process.env.REACT_APP_WIDGET_BASE_URL;
    let scriptCode = `<script type="text/javascript">
    (function () {
      window.ChatAIWidgetSettings = {
        appId: "${appId}",
        url: "${domainWiget}",
        customCss: {},
        isDebug: true
      }
      var loadSdk = function () {
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "${domainWiget}/js/widget.js?appId=${appId}";
        var x = document.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === "complete") {
      loadSdk();
    } else if (window.attachEvent) {
      window.attachEvent("onload", loadSdk);
    } else {
      window.addEventListener("load", loadSdk, false);
    }
    })();
    </script>`;
    return scriptCode;
  };

  const [form] = Form.useForm();
  const layout = {
    labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } },
  };
  const tailLayout = {
    wrapperCol: { xs: { span: 24 }, sm: { span: 24, offset: 0 }, md: { span: 24, offset: 0 }, lg: { span: 24, offset: 0 } },
  };
  const widgetFormKeys = [
    {
      key: 'headline',
      title: 'Headline',
      require: false,
    },
    {
      key: 'welcomeMessage',
      title: 'Welcome Message',
      subtitle: '',
    },
    // {
    //   key: 'startBoxWelcome',
    //   title: 'Start Box Welcome',
    //   subtitle: '',
    // },
    // {
    //   key: 'startBoxHint',
    //   title: 'Start Box Hint',
    //   subtitle: '',
    // },
    {
      key: 'widgetMessage',
      title: 'Widget Message',
      subtitle: '',
    },
    {
      key: 'widgetIcon',
      title: 'Widget Icon',
      subtitle: '',
      placeholder: 'http/https ...',
    },
    {
      key: 'allowDomains',
      title: keyToTitle('allowDomains'),
      subtitle: '',
      placeholder: 'abc.com.vn, *.com.vn ...',
    },
  ];
  const onFinish = async (values: any) => {
    console.log(values);
    if (isCreateNew) {
      setLoading(true);
      let param: any = {};
      widgetFormKeys.forEach(({ key: k }) => {
        param[k] = (botData as any)[k];
      });
      onNext(param);
      setLoading(false);
      return;
    }
    const confirmFn = async () => {
      setLoading(true);
      try {
        let param: any = {};
        widgetFormKeys.forEach(({ key: k }) => {
          param[k] = (botData as any)[k];
        });
        let dataUpdate = await updateBotApi({
          id: botData?.id,
          payload: param,
        });

        message.success(`Update widget success`);
        let botResult = await getBotByIdApi({ id: botData?.id });
        dispatch(setCurrentBotReduxAction(botResult));
      } catch (error) {
        console.error(error);
        message.error(JSON.stringify(error));
      }
      setLoading(false);
    };
    confirmFn();
    // Modal.confirm({
    //   title: 'Update bot ?',
    //   onOk:async()=>
    //   {
    //     confirmFn();
    //   },
    // })
  };

  const onReset = () => {
    form.resetFields();
    if (initFn) initFn();
  };

  // console.log(`bot data`, botData);
  // console.log(`currentProvider`, currentProvider);
  // console.log(`providers data`, providers);
  if (!botData) return null;
  return (
    <Form
      {...layout}
      // layout='horizontal'
      layout="vertical"
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      title="Bot Setting"
      // style={{ maxWidth: 600 }}
      className="form-edit-bot-instance bot-instance-profile"
    >
      <Card
        // title="Chat GPT"
        style={
          {
            // maxWidth:'60%',
            // margin: '0 auto',
          }
        }
        title={
          isCreateNew ? null : (
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <CurrentBotComponent
                  allBotItems={botItems}
                  // callback={(selectBot: any) => {
                  //   setBotData(selectBot);
                  // }}
                />
              </Col>
              <Col span={12}>
                <PreviewWebChatComponent botData={botData} />
              </Col>
            </Row>
          )
        }
      >
        <div
          style={{
            maxWidth: '70%',
            margin: '0 auto',
          }}
        >
          {loading && <LoadingComponent />}
          {widgetFormKeys.map(({ key, title, subtitle, placeholder, require }, idx) => {
            return (
              <>
                {key == 'allowDomains' && <Divider>Security</Divider>}
                <Form.Item
                  initialValue={(botData as any)[key]}
                  key={key + idx}
                  className="form-item-label-has-subtitle"
                  name={key}
                  label={
                    <>
                      <span className="title">{keyToTitle(key)}</span>
                      <span className="subtitle" style={{ left: 0 }}>
                        {keyToTitle(key)}
                      </span>
                    </>
                  }
                  rules={[{ required: require }]}
                >
                  {key == 'lang' ? (
                    <Select
                      value={botData?.lang}
                      onChange={(e) => {
                        console.log(`select model`, e);
                        botData.lang = e;
                        setBotData(botData);
                      }}
                      options={LANGUAGES.map((lang) => {
                        return {
                          label: lang.name,
                          value: lang.name,
                        };
                      })}
                    />
                  ) : key == 'status' ? (
                    <Switch
                      loading={loading}
                      disabled={loading}
                      value={(botData as any)['status'] == 1}
                      // checked={(botData as any)['status'] == 1}
                      onChange={async (e) => {
                        console.log(`toogle change `, botData, e);
                        botData['status'] = e === false ? 0 : 1;
                        setBotData(botData);
                      }}
                    />
                  ) : key == 'widgetIcon' ? (
                    <>
                      <Space>
                        <Avatar src={(botData as any)[key as string]} style={{ width: '80px', height: '80px' }} />
                        <Upload {...propsUploadAvatar} className="profile-avatar-btn-upload" style={{ position: 'absolute' }}>
                          <Button icon={<UploadOutlined />}></Button>
                        </Upload>
                      </Space>
                    </>
                  ) : (
                    <Input
                      placeholder={placeholder || ' '}
                      onChange={(e) => {
                        (botData as any)[key] = e.target.value;
                        setBotData(botData);
                      }}
                      value={(botData as any)[key as string]}
                      // defaultValue={(botData as any)[key] as string}
                      disabled={loading}
                    />
                  )}
                </Form.Item>
              </>
            );
          })}

          <Form.Item {...tailLayout} style={{ marginTop: '15px' }}>
            <Space>
              {isCreateNew ? (
                <>
                  <Button style={{ margin: '0 8px' }} onClick={() => onPrev()}>
                    <ArrowLeftOutlined /> Previous
                  </Button>
                  <Button type="primary" htmlType="submit">
                    <CheckCircleOutlined /> Submit Create
                  </Button>
                </>
              ) : (
                <>
                  <Button type="primary" htmlType="submit" disabled={loading}>
                    Submit
                  </Button>
                  <Button htmlType="button" onClick={onReset} disabled={loading}>
                    Cancel
                  </Button>
                  <AntColorButton
                    type="warning"
                    onClick={(e) => {
                      setisOpenModalGetScript(true);
                    }}
                  >
                    <CodeFilled /> Get Script
                  </AntColorButton>
                </>
              )}

              {/* <Button htmlType="button" type='primary' onClick={onGetScript} disabled={loading}>
                <CodeFilled/> Get Script
              </Button> */}
            </Space>
          </Form.Item>
        </div>
      </Card>
      <Modal
        width={'90%'}
        title="Get cript"
        open={isOpenModalGetScript}
        onCancel={(e) => {
          setisOpenModalGetScript(false);
        }}
        onOk={(e) => {
          //copy
          setisOpenModalGetScript(false);
        }}
        okText="Copy"
        cancelText="Cancel"
        okButtonProps={{
          style: {
            display: 'none',
          },
        }}
      >
        <div>
          <Typography.Paragraph
            copyable={{
              icon: [<CopyFilled key="copy-icon" />, <SmileFilled key="copied-icon" />],
              tooltips: ['click here', 'copied!'],
              text: getscriptCode(botData?.id),
              format: 'text/plain',
            }}
          >
            <pre>
              <code>{getscriptCode(botData?.id)}</code>
            </pre>
          </Typography.Paragraph>
        </div>
      </Modal>
    </Form>
  );
};

export default BotInstanceWidgetPage;
