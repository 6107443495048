import { blue, red } from '@ant-design/colors';
import { logError, logInfo, logSuccess, logWarn } from '../utils/logHelper';

export const NODE_ENV = process.env.NODE_ENV;
export const API_URL = process.env.REACT_APP_API_URL;
export const APP_URL = process.env.REACT_APP_BASE_URL;
logInfo(`Start env ${process.env.NODE_ENV}`, {
  API_URL,
  APP_URL,
});
// logSuccess(`Start env ${process.env.NODE_ENV}`,{
//   API_URL,
// })
// logError(`Start env ${process.env.NODE_ENV}`,{
//   API_URL,
// })
// logWarn(`Start env ${process.env.NODE_ENV}`,{
//   API_URL,
// })
export const COOKIES_TOKEN_KEY = 'chatai-token';
export const COOKIES_USER_KEY = 'chatai-user';
export const TOKEN_AUTH_KEY = 'chatai-admin-token';
export const USER_AUTH_KEY = 'chatai-admin-user';
export const CURRENT_BOT_KEY = 'chatai-admin-current-bot';
export const CURRENT_BOT_WEB_CHAT_KEY = 'chatai-web-current-bot';

export const LANG_EN = 'en';
export const LANG_VN = 'vn';

export const THEME_MAIN = 'main'; //antd
export const THEME_TLN = 'tln'; //tln https://www.figma.com/file/OXJ7PAodiEbH9vPOMsee9n/Design-system-export-in-0104-(1)
export const THEME_KAOLA = 'kaola';
export const GPT_MODEL_4 = 'gpt-4';
export const GPT_MODEL_4_TURBO = 'gpt-4-turbo';
export const GPT_MODEL_3_5 = 'gpt-3.5';
export const GPT_MODEL_3_5_TURBO = 'gpt-3.5-turbo';
export const RAG_STRATEGY = [
  { value: 'naive', label: 'naive' },
  { value: 'compress', label: 'compress' },
  { value: 'expanding', label: 'expanding' },
  // ['naive', 'compress', 'expanding']
];
export const ROLE_ADMIN = 'admin'; //admin/user/operator/trainer
export const ROLE_USER = 'user';
export const ROLE_TRAINER = 'trainer'; 
export const ROLE_OPERATOR = 'operator';
export const ROLE_POWER_OPERATOR = 'power_operator';
export const LANGUAGES = [
  { code: 'en', name: 'English' },
  { code: 'vi', name: 'Vietnamese' },
  { code: 'es', name: 'Spanish' },
  { code: 'fr', name: 'French' },
  { code: 'de', name: 'German' },
  { code: 'zh', name: 'Chinese' },
  { code: 'ar', name: 'Arabic' },
  { code: 'hi', name: 'Hindi' },
  { code: 'ru', name: 'Russian' },
  { code: 'ja', name: 'Japanese' },
  { code: 'pt', name: 'Portuguese' },
  { code: 'it', name: 'Italian' },
  { code: 'ko', name: 'Korean' },
  { code: 'tr', name: 'Turkish' },
  { code: 'nl', name: 'Dutch' },
  { code: 'pl', name: 'Polish' },
  { code: 'sv', name: 'Swedish' },
  { code: 'cs', name: 'Czech' },
  { code: 'el', name: 'Greek' },
  { code: 'th', name: 'Thai' },
  { code: 'da', name: 'Danish' },
  { code: 'fi', name: 'Finnish' },
  { code: 'no', name: 'Norwegian' },
  { code: 'hu', name: 'Hungarian' },
  { code: 'he', name: 'Hebrew' },
  { code: 'ro', name: 'Romanian' },
  { code: 'id', name: 'Indonesian' },
  { code: 'uk', name: 'Ukrainian' },
  { code: 'ms', name: 'Malay' },
  { code: 'ca', name: 'Catalan' },
  { code: 'sk', name: 'Slovak' },
  { code: 'bg', name: 'Bulgarian' },
  { code: 'sr', name: 'Serbian' },
  { code: 'lt', name: 'Lithuanian' },
  { code: 'hr', name: 'Croatian' },
  { code: 'sl', name: 'Slovenian' },
  { code: 'et', name: 'Estonian' },
  { code: 'lv', name: 'Latvian' },
  { code: 'fa', name: 'Persian' },
  { code: 'sq', name: 'Albanian' },
  { code: 'eu', name: 'Basque' },
  { code: 'is', name: 'Icelandic' },
  { code: 'mk', name: 'Macedonian' },
  { code: 'ur', name: 'Urdu' },
  { code: 'bn', name: 'Bengali' },
  { code: 'sw', name: 'Swahili' },
  { code: 'gl', name: 'Galician' },
  { code: 'ta', name: 'Tamil' },
  { code: 'mr', name: 'Marathi' },
  { code: 'uk', name: 'Ukrainian' },
  { code: 'ml', name: 'Malayalam' },
  { code: 'kn', name: 'Kannada' },
  { code: 'gu', name: 'Gujarati' },
  { code: 'te', name: 'Telugu' },
  { code: 'pa', name: 'Punjabi' },
];
export const themeMainSetting = {
  colorPrimary: blue.primary || '#1677ff',
  colorInfo: blue.primary || '#1677ff',
  colorError: red.primary || '#ff4d4f',
  colorSuccess: '#52c41a',
  colorWarning: '#faad14',
};

export const themeKaolaSetting = {
  colorPrimary: '#51b8f1',
  colorInfo: '#51b8f1',
  colorError: '#e55c5c',
  colorSuccess: '#58a732',
  colorWarning: '#f0a818',
};

export const themeTlnSetting = {
  colorPrimary: '#9E77ED',
  colorInfo: '#51b8f1',
  colorError: '#F04438',
  colorSuccess: '#17B26A',
  colorWarning: '#F79009',
};

export const FeedbackStatusEnum = {
  request: 'request',
  approve: 'approve',
  deny: 'deny',
};
