/* eslint-disable */
import { apiClient } from './axiosBase';
import { API_URL } from '../consts/constApp';

export const getAxiosForUploadImage = ({ formData, config }: any) => {
  return apiClient.post('/api/adm/resource/images', formData, config);
};
//
//
// Bot instance
// Bot instance
// Bot instance
//
export const getAllBotApi = async () => {
  const url = `/api/adm/bot-instance`;
  try {
    let result = await apiClient.get(url, {
      // appId: 'appId'
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};
export const creteBotApi = async ({ payload }: any) => {
  const url = `/api/adm/bot-instance`;
  try {
    let result = await apiClient.post(url, {
      // appId: 'appId'
      ...payload,
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};

export const updateBotApi = async ({ id, payload }: any) => {
  const url = `/api/adm/bot-instance/${id}`;
  try {
    let result = await apiClient.put(url, {
      // appId: 'appId'
      ...payload,
      id,
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};
export const getBotByIdApi = async ({ id }: any) => {
  const url = `/api/adm/bot-instance/${id}`;
  try {
    let result = await apiClient.get(url, {});
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};

//
//
// Provider
// Provider
// Provider
//
export const getProviderApi = async () => {
  const url = `/api/adm/setting/providers`;
  try {
    let result = await apiClient.get(url, {
      // appId: 'appId'
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};
export const updateProviderApi = async ({ id, payload }: any) => {
  const url = `/api/adm/setting/providers/${id}`;
  try {
    let result = await apiClient.put(url, {
      ...(payload || {}),
      id,
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};
export const updateModelOfProviderApi = async ({ id, payload }: any) => {
  const url = `/api/adm/setting/models/${id}`;
  try {
    let result = await apiClient.put(url, {
      ...(payload || {}),
      id,
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};
export const checkProviderApiKeyApi = async ({ id, apiKey }: any) => {
  const url = `/api/adm/setting/providers/${id}/check?apiKey=${apiKey}`;
  try {
    let result = await apiClient.get(url, {});
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};

export const getAllModels = async ({ status }: { status?: any }) => {
  const url = `/api/adm/setting/models`;
  try {
    let result = await apiClient.get(url, {
      params: {
        ...(status != undefined ? { status } : {}),
      },
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};

export const createNewModelOfProvider = async ({ payload }: any) => {
  const url = `/api/adm/setting/models`;
  try {
    let result = await apiClient.post(url, {
      model: '',
      modelType: '',
      contextLength: '',
      providerId: '',
      ...payload,
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};

//
//
// Document
// Document
// Document
//
export const listDocumentApi = async ({ botId, page, limit }: any) => {
  let url = `/api/adm/knowledge?`;
  url += botId ? `botId=${botId}` : '';
  try {
    let result = await apiClient.get(url, {
      headers: {
        ['x-bot-id']: botId,
      },
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};
export const removeDocumentApi = async ({ id }: any) => {
  const url = `/api/adm/knowledge/${id}`;
  try {
    let result = await apiClient.delete(url, {});
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};
export const uploadTrainDocument = async ({ title, fileType, summary, botId, fileObject, onProgressEventCb }: any) => {
  const url = `/api/adm/knowledge/train`;
  const formData = new FormData();
  formData.append('title', title);
  formData.append('name', title);
  formData.append('fileType', fileType);
  formData.append('summary', summary);
  formData.append('bot-id', botId);
  // for (const name in values) {
  formData.append('file', fileObject); // there should be values.avatar which is a File object
  // }
  try {
    let result = await apiClient.post(
      url,
      formData, //// automagically sets content-type: multipart/form-data header
      {
        headers: {
          'content-type': 'multipart/form-data',
          'x-bot-id': botId,
        },
        onUploadProgress: (progressEvent) => {
          if (onProgressEventCb) onProgressEventCb(progressEvent);
          // var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        },
      }
    );
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};

//
//
//
export const getAllRagModelApi = async () => {
  const url = `/api/adm/setting`;
  try {
    let result = await apiClient.get(url, {
      // appId: 'appId'
    });
    return result.data.data?.ragMode || [];
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};

//Users
//Users
//Users
export const getUserByIdApi = async (id: any) => {
  const url = `/api/adm/users/${id}`;
  try {
    let result = await apiClient.get(url, {
      // appId: 'appId'
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};
export const getListUserApi = async ({ q, page, limit }: any) => {
  //search?q=rio
  const url = `/api/adm/users${q ? `/search?q=${q}` : ''}`;
  try {
    let result = await apiClient.get(url, {
      // appId: 'appId'
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};

export const getListAdUserApi = async ({ q, page, limit }: any) => {
  //search?q=rio
  const url = `/api/adm/users/saml/search?q=${q}`;
  try {
    let result = await apiClient.get(url, {
      // appId: 'appId'
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};
export const addADUserApi = async (payload: any) => {
  //search?q=rio
  const url = `/api/adm/users/add`;
  try {
    let result = await apiClient.post(url, {
      // appId: 'appId'
      ...(payload || {}),
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};
export const updateUserApi = async (id: string, payload: any) => {
  //search?q=rio
  const url = `/api/adm/users/${id}`;
  try {
    let result = await apiClient.put(url, {
      // appId: 'appId'
      ...(payload || {}),
      id,
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};

// Data management
// Data management
// Data management
export const searchVectorDataApi = async ({ q, page, limit, botId }: { q?: string; page?: any; limit?: any; botId?: any }) => {
  let pag = typeof page != 'undefined' ? page - 1 : 0;
  let url = `/api/adm/knowledge/vector/search?q=${q}&page=${pag}&limit=${limit || 20}`;
  if (botId) url += `&botId=${botId}`;
  try {
    let result = await apiClient.get(url, {
      // appId: 'appId'
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};
export const retrainVectorDataApi = async ({ botId, embeddingId, text }: any) => {
  // api/adm/knowledge/vector/retrain
  const url = `api/adm/knowledge/vector/retrain/${embeddingId}`;
  try {
    let result = await apiClient.put(url, {
      botId,
      embeddingId,
      text,
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};

export const addVectorDataApi = async ({ botId, text }: any) => {
  // api/adm/knowledge/vector/retrain
  const url = `api/adm/knowledge/vector/add`;
  try {
    let result = await apiClient.post(url, {
      botId,
      text,
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};

//Feedback
//Feedback
//Feedback
export const feedbackListApi = async ({ q, page, limit, botId, status, params }: { q?: string; page?: any; limit?: any; botId?: any; status?: any; params?: any }) => {
  let pag = typeof page != 'undefined' ? page - 1 : 0;
  let url = `/api/adm/feedback/search?q=${q}&page=${pag}&limit=${limit || 20}`;
  // if (botId) url += `&botId=${botId}`;
  // if (status) {
  //   url += `&status=${status}`;
  //   url += `&feedbackStatus=${status}`;
  // }
  try {
    let result = await apiClient.get(url, {
      // appId: 'appId'
      params: params || {},
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};
export const feedbackUpdateApi = async ({ id, payload }: any) => {
  let url = `/api/adm/feedback/${id}`;
  try {
    let result;
    if (payload && payload.action == 'delete') {
      result = await apiClient.delete(url, {
        // appId: 'appId'
      });
    } else {
      result = await apiClient.put(url, {
        // appId: 'appId'
        ...payload,
      });
    }
    // console.log(url, result.data.data);
    return result?.data?.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};

//chathistory
//chathistory
//chathistory
export const getListChatHistoryApi = async ({ deviceId, page, limit, search }: any = null) => {
  try {
    let nPage = (page || 1) - 1;
    let url = `/api/adm/history`;

    url = url + `?page=${nPage}&limit=${limit || 20}&deviceId=${deviceId || ''}`;
    if (search && search.from) {
      url += `&from=${search.from}&to=${search.to}`;
    }
    if (search && search.tags) {
      url += `&tags=${search.tags}`;
    }
    if (search && search.botId) {
      url += `&botId=${search.botId}`;
    }
    if (search && search.tagType) {
      url += `&tagType=${search.tagType}`;
    }
    let result = await apiClient.get(url);
    // console.log(url, result.data.data);
    return result.data.data || result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

//tags
export const getListTags = async (params: any = null) => {
  try {
    // let nPage = (page || 1) - 1;
    let url = `/api/adm/tag`;
    // url = url + `?page=${nPage}&limit=${limit || 20}&deviceId=${deviceId || ''}`;
    // if(search && search.from){
    //   url += `&from=${search.from}&to=${search.to}`;
    // }
    let result = await apiClient.get(url);
    // console.log(url, result.data.data);
    return result.data.data || result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const tagListApi = async ({ q, page, limit, botId, status }: { q?: string; page?: any; limit?: any; botId?: any; status?: any }) => {
  let pag = typeof page != 'undefined' ? page - 1 : 0;
  let url = `/api/adm/tag?q=${q || ''}&page=${pag}&limit=${limit || 20}`;
  // if (botId) url += `&botId=${botId}`;
  // if (status) {
  //   url += `&status=${status}`;
  //   url += `&feedbackStatus=${status}`;
  // }
  try {
    let result = await apiClient.get(url, {
      // appId: 'appId'
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};
export const addTagApi = async ({ payload }: any) => {
  const url = `/api/adm/tag`;
  try {
    let result = await apiClient.post(url, {
      // appId: 'appId'
      ...payload,
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};
export const updateTagApi = async ({ id, payload }: any) => {
  const url = `/api/adm/tag/${id}`;
  try {
    let result = await apiClient.put(url, {
      // appId: 'appId'
      ...payload,
      id,
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};
export const deleteTagApi = async ({ id }: any) => {
  let url = `/api/adm/tag/${id}`;
  try {
    let result = await apiClient.delete(url, {
      // appId: 'appId'
    });
    // console.log(url, result.data.data);
    return result?.data?.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};

//report
export const loadReportApi = async ({ q, page, limit, from, to }: { q?: string; page?: any; limit?: any; from?: any; to?: any }) => {
  let pag = typeof page != 'undefined' ? page - 1 : 0;
  let url = `/api/adm/report`;
  // if (botId) url += `&botId=${botId}`;
  // if (status) {
  //   url += `&status=${status}`;
  //   url += `&feedbackStatus=${status}`;
  // }
  try {
    let result = await apiClient.get(url, {
      // appId: 'appId'
      params: {
        from,
        to,
        q,
        page,
        limit,
      },
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};
