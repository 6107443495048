/* eslint-disable */
import { useState, CSSProperties, useEffect } from 'react';
import { useNavigate, useLoaderData, Navigate, useSearchParams, Link } from 'react-router-dom';
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Drawer,
  Dropdown,
  DropDownProps,
  Form,
  Input,
  message,
  Modal,
  Progress,
  Result,
  Row,
  Select,
  Space,
  Switch,
  Table,
  TableProps,
  Tabs,
  Tag,
  Tooltip,
  Typography,
  Upload,
  UploadProps,
} from 'antd';
import {
  AlipayOutlined,
  AppleOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
  DeleteFilled,
  DownOutlined,
  EditFilled,
  EditOutlined,
  FacebookOutlined,
  FileExcelFilled,
  FilePdfFilled,
  FileTextFilled,
  FileUnknownFilled,
  FileWordFilled,
  GoogleOutlined,
  HeatMapOutlined,
  LockOutlined,
  MessageOutlined,
  MobileOutlined,
  PlayCircleFilled,
  PlusCircleFilled,
  PlusCircleOutlined,
  PlusOutlined,
  ProfileFilled,
  ReloadOutlined,
  SearchOutlined,
  TaobaoOutlined,
  UploadOutlined,
  UserOutlined,
  WeiboOutlined,
} from '@ant-design/icons';
import { LoginForm, ProFormCaptcha, ProFormCheckbox, ProFormText } from '@ant-design/pro-components';
import { store, useAppDispatch, useAppSelector } from '../store';
import { selectAuthState, loginByUserPassThunk, getUserInfoThunkAction } from '../reduxs/authSlice';
import { selectLanuageState, selectThemeState } from '../reduxs/themeSlice';
import { useTranslation } from 'react-i18next';
import { loginGoogleApi } from '../apis/authApi';
import Loading, { LoadingComponent } from '../components/loading';
import Paragraph from 'antd/lib/skeleton/Paragraph';
import { GPT_MODEL_3_5, GPT_MODEL_3_5_TURBO, GPT_MODEL_4, GPT_MODEL_4_TURBO, LANGUAGES, RAG_STRATEGY, ROLE_ADMIN, ROLE_OPERATOR, ROLE_POWER_OPERATOR, ROLE_TRAINER, ROLE_USER } from '../consts/constApp';
import { formatBytes, getCurrentBot, keyToTitle } from '../utils/appHelper';
import {
  addADUserApi,
  getAllBotApi,
  getBotByIdApi,
  getListAdUserApi,
  getListUserApi,
  getProviderApi,
  getUserByIdApi,
  listDocumentApi,
  removeDocumentApi,
  updateBotApi,
  updateUserApi,
  uploadTrainDocument,
} from '../apis/appApi';
import { MenuProps } from 'antd/lib';
import { BotDataType } from '../types/appTypes';
import CurrentBotComponent from '../components/currentBot/currentBotComponent';
import { selectAppState, setCurrentBotReduxAction } from '../reduxs/appSlice';
import BotCreateComponent from '../components/botCreate/botCreatecomponent';
const { Search } = Input;
const UserManagementPage = () => {
  const { t, i18n } = useTranslation();
  const userInfo = useLoaderData();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuthState);
  const appState = useAppSelector(selectAppState);
  const themeState = useAppSelector(selectThemeState);
  const lang = useAppSelector(selectLanuageState);
  const [searchParams, setSearchParams] = useSearchParams();
  const [botItems, setBotItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpenModalCreate, setisOpenModalCreate] = useState(false);
  const [isOpenAddADUser, setisOpenAddADUser] = useState(false);
  const [documents, setDocuments] = useState<any>([]);
  const [users, setusers] = useState<any>([]);
  const [adUsers, setadUser] = useState<any>([]);
  const [currUser, setcurrUser] = useState<any>({});
  // const [botData, setBotData]: [BotDataType, any] = useState((getCurrentBot() as BotDataType) || {});
  const [formEditUser] = Form.useForm();
  const fieldFormEditUser = [
    { key: 'username', disable: true, require: true },
    { key: 'email', disable: true, require: true },
    { key: 'firstName', disable: false, require: true },
    { key: 'lastName', disable: false, require: true },
    { key: 'role', disable: false, require: true, type: 'select' },
    { key: 'bot', disable: false, require: true },
  ];
  const columns: TableProps<any>['columns'] = [
    {
      title: keyToTitle('username'),
      dataIndex: 'username',
      key: 'username',
      render: (username, record, index) => (
        <Space
          style={{ cursor: 'pointer' }}
          onClick={async (e) => {
            await onOpenModalEditUser(record);
          }}
        >
          <Avatar src={record.avatar} />
          <span style={{ marginLeft: '5px', textDecoration: 'underline' }}>{username}</span>
        </Space>
      ),
    },
    {
      title: 'Fullname',
      dataIndex: 'fullname',
      key: 'username',
      render: (_, record, index) => <span>{`${record.lastName || ''} ${record.firstName || ''}`}</span>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (email) => <span>{email}</span>,
    },

    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      render: (state) => <span>{state}</span>,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (role) => <span>{role}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, record, index) => (
        <>
          <Switch
            defaultChecked
            value={status === 1}
            onChange={async (e) => {
              console.log(`change `, e);
              await onSubmitUpdateStatusUser(record, e === true ? 1 : 0);
            }}
          />
          {/* <Tag color={status == 1 ? 'success' : 'default'} key={status}>
            {status == 1 ? 'Active' : 'Inactive'}
          </Tag> */}
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title={'Edit info'}>
            <EditOutlined
              onClick={async (e) => {
                await onOpenModalEditUser(record);
              }}
              style={{ color: 'blue' }}
            />
          </Tooltip>
          {/* <Tooltip title={'Sync with MS account'}>
            <ReloadOutlined
              onClick={(e) => {
                message.success(`Completed succcesfully`);
              }}
              style={{ color: 'blueviolet' }}
            />
          </Tooltip> */}
        </Space>
      ),
    },
  ];
  const columnADUser: TableProps<any>['columns'] = [
    {
      title: keyToTitle('username'),
      dataIndex: 'username',
      key: 'username',
      render: (username, record, index) => (
        <Space
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            setisOpenModalCreate(true);
            setcurrUser(record);
          }}
        >
          <Avatar src={record.avatar} />
          <span style={{ marginLeft: '5px', textDecoration: 'underline' }}>{username}</span>
        </Space>
      ),
    },
    {
      title: 'Fullname',
      dataIndex: 'fullname',
      key: 'username',
      render: (_, record, index) => <span>{`${record.lastName} ${record.firstName}`}</span>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (email) => <span>{email}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title={'Add'}>
            <PlusCircleOutlined
              onClick={async (e) => {
                // setisOpenModalCreate(true);
                // setcurrUser(record);
                await onSubmitCreateADUser(record);
              }}
              style={{ color: 'blue' }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  const onOpenModalEditUser = async (record: any) => {
    setLoading(true);
    try {
      let result = await getUserByIdApi(record?.id);
      setcurrUser(result || record);
    } catch (error: any) {
      console.error(error);
      message.error(error?.message || JSON.stringify(error));
    }
    setLoading(false);
    setisOpenModalCreate(true);
  };

  const loadUsers = async (q = null) => {
    setLoading(true);
    try {
      let result = await getListUserApi({ q });
      let users = result.items.map((r: any, idx: number) => {
        return {
          ...r,
          key: r.id || idx,
        };
      });
      setusers(users);
    } catch (error) {
      console.error(error);
      message.error(((error as any) || {})['message'] || JSON.stringify(error));
    }
    setLoading(false);
  };
  const loadAdUser = async (q = null) => {
    setLoading(true);
    try {
      let result = await getListAdUserApi({ q });
      let users = result.items.map((r: any) => {
        return {
          ...r,
          key: r.id,
        };
      });
      setadUser(users);
    } catch (error) {
      console.error(error);
      message.error(JSON.stringify(error));
    }
    setLoading(false);
  };
  const initFn: any = async () => {
    const [_, result] = await Promise.all([loadUsers(), getAllBotApi()]);
    setBotItems(result.items);
  };
  const onSubmitCreateADUser = async (record: any) => {
    setLoading(true);
    try {
      let result = await addADUserApi(record);
      message.success(`Add user success`);
    } catch (error: any) {
      console.error(error);
      message.error(error?.message || JSON.stringify(error));
    }
    setLoading(false);
  };
  useEffect(() => {
    initFn();
  }, []);
  const parseBotDefaultValue = (user: any) => {
    if (Array.isArray(user?.bots)) {
      return user?.bots || [];
    }
    let botIds =
      String(user?.bots || '')
        .split(',')
        .map((r) => r.trim()) || [];
    return botIds.filter((id) => !!botItems.find((r: any) => r.id == id));
  };
  const onSubmitEditCreateUser = async () => {
    if (!currUser?.id) {
      // setLoading(true);
      // try {
      //   await add User Api({ ...currUser });
      //   message.success(`Create user success`);
      //   await loadUsers();
      // } catch (error: any) {
      //   console.error(error);
      //   message.error(error?.message || JSON.stringify(error));
      // }
      // setLoading(false);
      message.error(`Feature not available`);
    } else {
      setLoading(true);
      try {
        await updateUserApi(currUser?.id, { ...currUser });
        message.success(`Update user success`);
        await loadUsers();
      } catch (error: any) {
        console.error(error);
        message.error(error?.message || JSON.stringify(error));
      }
      setLoading(false);
    }
  };
  const onSubmitUpdateStatusUser = async (record: any, status: number) => {
    setLoading(true);
    try {
      await updateUserApi(record?.id, { status: status });
      message.success(`Change status success`);
      await loadUsers();
    } catch (error: any) {
      console.error(error);
      message.error(error?.message || JSON.stringify(error));
    }
    setLoading(false);
  };
  return (
    <div className="list-bot-instance">
      <Card
        title={
          <Row>
            <Col span={12}>
              {/* <Space>
                <Tooltip title="Add SAML User">
                  <Button
                    icon={<PlusCircleOutlined />}
                    onClick={(e) => {
                      setisOpenAddADUser(true);
                      setcurrUser({});
                      setadUser([]);
                    }}
                  >
                    Add AD User
                  </Button>
                </Tooltip>
              </Space> */}
            </Col>
            <Col span={4}> </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
              <Input.Search
                placeholder="input search text"
                allowClear
                onSearch={async (value, _e, info) => {
                  console.log(info?.source, value);
                  await loadUsers(value as any);
                }}
                enterButton
              />
            </Col>
          </Row>
        }
      >
        {loading && <LoadingComponent />}

        <div className="list">
          <Table columns={columns} dataSource={users} />
        </div>
        {isOpenModalCreate && (
          <Drawer
            visible={isOpenModalCreate}
            onClose={() => {
              setisOpenModalCreate(false);
            }}
            width="40VW"
            title={
              <Space>
                <Avatar src={currUser?.avatar} />
                <label>{currUser?.username || currUser?.email || 'New User'}</label>
              </Space>
            }
            placement="right"
            extra={
              <Space>
                <Button
                  onClick={() => {
                    setisOpenModalCreate(false);
                    setcurrUser({});
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    // setisOpenModalCreate(false);
                    onSubmitEditCreateUser();
                  }}
                >
                  Submit
                </Button>
              </Space>
            }
          >
            <Form
              labelCol={{ xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } }}
              wrapperCol={{ xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: 24 } }}
              // layout='horizontal'
              layout="vertical"
              form={formEditUser}
              name="control-hooks"
              onFinish={() => {}}
              title="Edit user"
              // style={{ maxWidth: 600 }}
              className="form-edit-bot-instance bot-instance-profile"
            >
              <div>
                {loading && <LoadingComponent />}
                {fieldFormEditUser.map(({ key, disable, require }, idx) => {
                  return (
                    <Form.Item
                      initialValue={(currUser as any)[key]}
                      key={key + idx}
                      className="form-item-label-has-subtitle1"
                      name={key}
                      label={
                        <>
                          <span className="title">{keyToTitle(key)}</span>
                          {/* <span className="subtitle" style={{ left: 0 }}>
                      {keyToTitle(key)}
                    </span> */}
                        </>
                      }
                      rules={[{ required: require }]}
                    >
                      {key == 'bot' ? (
                        <Select
                          mode="multiple"
                          allowClear
                          style={{ width: '100%' }}
                          placeholder="Please select"
                          defaultValue={parseBotDefaultValue(currUser)}
                          onChange={(value: string[]) => {
                            console.log(`selected bot ${value}`, value);
                            setcurrUser({ ...currUser, bots: value.join(',') });
                          }}
                          options={botItems.map((r: any) => {
                            return {
                              label: r.name,
                              value: r.id,
                            };
                          })}
                        />
                      ) : key == 'role' ? (
                        <Select
                          style={{ width: '200px' }}
                          value={currUser?.role}
                          onChange={(e) => {
                            console.log(`select role`, e);
                            setcurrUser({ ...currUser, role: e });
                          }}
                          options={[
                            {
                              label: 'Admin',
                              value: ROLE_ADMIN,
                            },
                            {
                              label: 'User',
                              value: ROLE_USER,
                            },
                            {
                              label: keyToTitle(ROLE_TRAINER),
                              value: ROLE_TRAINER,
                            },
                            {
                              label: keyToTitle(ROLE_OPERATOR),
                              value: ROLE_OPERATOR,
                            },
                            {
                              label: keyToTitle(ROLE_POWER_OPERATOR),
                              value: ROLE_POWER_OPERATOR,
                            },
                          ]}
                        />
                      ) : (
                        <Input
                          placeholder={' '}
                          onChange={(e) => {
                            setcurrUser({ ...currUser, [key]: e.target.value });
                          }}
                          value={(currUser as any)[key as string]}
                          // defaultValue={(botData as any)[key] as string}
                          disabled={disable}
                        />
                      )}
                    </Form.Item>
                  );
                })}
              </div>
            </Form>
          </Drawer>
        )}
        {isOpenAddADUser && (
          <Modal
            open={true}
            title="Add AD User"
            footer={null}
            width={'80%'}
            onCancel={(e) => {
              setisOpenAddADUser(false);
            }}
          >
            <Row>
              <Col span={12}>
                <Input.Search
                  placeholder="input search text"
                  onSearch={async (value, _e, info) => {
                    console.log(info?.source, value);
                    if (value) {
                      await loadAdUser(value as any);
                    }
                  }}
                  enterButton
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '15px' }}>
              <Col span={24}>
                <Table dataSource={adUsers} columns={columnADUser} />
              </Col>
            </Row>
          </Modal>
        )}
      </Card>
    </div>
  );
};

export default UserManagementPage;
