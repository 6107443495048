import { CURRENT_BOT_KEY, CURRENT_BOT_WEB_CHAT_KEY } from '../consts/constApp';
import { BotDataType } from '../types/appTypes';

export const timeout = (time = 1) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(null);
    }, time * 1000);
  });
};
// abcText => Abc Text
export const keyToTitle = (key: string): string => {
  if(String(key).includes('_')){
    let  textArr = String(key).split('_');
    textArr = textArr.map(r=>{
      return r.charAt(0).toUpperCase() + r.slice(1);
    })
    return textArr.join(' ');
  }
  const text = key;
  const result = text.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  // console.log(finalResult);
  return finalResult;
};

export const setCurrentBot = (bot: BotDataType) => {
  localStorage.setItem(CURRENT_BOT_KEY, JSON.stringify(bot));
};
export const getCurrentBot = () => {
  try {
    return JSON.parse(localStorage.getItem(CURRENT_BOT_KEY) as string);
  } catch (error) {
    return null;
  }
};
export const setBotForPreviewWebChat = (bot: any) => {
  localStorage.setItem(CURRENT_BOT_WEB_CHAT_KEY, JSON.stringify(bot));
};
export const formatBytes = (bytes: any, decimals = 2) => {
  if (!+bytes) return '0 Bytes';
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const unitSize = sizes[i];

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${unitSize}`;
};
export const isLocalhost = () => {
  return window.location.href.includes('localhost');
};
export const randomIntFromInterval = (min: number, max: number) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};
export const randomTagClass = (number: any = null) => {
  let tags = [
    'success',
    'processing', 'error', 'warning', 'default',
     '#f50', '#2db7f5', '#87d068', '#108ee9',
    'magenta',
    'red',
    'volcano',
    'orange',
    'gold',
    'lime',
    'green',
    'cyan',
    'blue',
    'geekblue',
    'purple',
  ];
  if (typeof number == 'string') {
    if (tags.includes(number)) return number;
  }
  if ((number as any) >= 0 && tags[number]) {
    return tags[number];
  }
  return tags[randomIntFromInterval(0, tags.length - 1)];
};
