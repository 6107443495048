/* eslint-disable */
import { lazy } from 'react';
import { Link, Navigate } from 'react-router-dom';
import {
  AppstoreOutlined,
  BarChartOutlined,
  BoldOutlined,
  DashboardOutlined,
  DesktopOutlined,
  ForkOutlined,
  HistoryOutlined,
  KeyOutlined,
  MailOutlined,
  OrderedListOutlined,
  PieChartOutlined,
  ProfileFilled,
  RedEnvelopeFilled,
  RocketFilled,
  SearchOutlined,
  SettingOutlined,
  TableOutlined,
  TabletFilled,
  TagFilled,
  TrophyOutlined,
  UsergroupAddOutlined,
  WechatOutlined,
  WindowsFilled,
} from '@ant-design/icons';

import LoginPage from '../pages/loginPage';
import { MenuRoute, RoutesType, MenuItem, BreadcrumbMap } from './types';
import { checkAuth, checkIsNotAuth } from './guard';
import AdminLayout from './../layout/layout';
import ForbiddenPage from '../pages/forbiddenPage';
import NotFoundPage from '../pages/notfoundPage';
import HomePage from '../pages/homePage';
import LoginCallback from '../pages/loginCallback';
import ApiKeyPage from '../pages/apiKeyPage';
import BotInstanceLLMPage from '../pages/botInstanceLLMPage';
import BotInstanceProfilePage from '../pages/botInstanceProfilePage';
import BotInstanceWidgetPage from '../pages/botInstanceWidgetPage';
import BotInstanceTrainDataPage from '../pages/botInstanceTrainDataPage';
import BotInstanceListPage from '../pages/botInstanceListPage';
import Icon from '@ant-design/icons/lib/components/Icon';
import UserManagementPage from '../pages/userManagement';
import VectorDataListPage from '../pages/dataManagement/vectorDataListPage';
import FeedbackPage from '../pages/feedback/feedbackPage';
import ChatHistoryPage from '../pages/history/chatHistoryPage';
import TagsPage from '../pages/tag/tagPage';
import { ROLE_ADMIN, ROLE_OPERATOR, ROLE_POWER_OPERATOR, ROLE_TRAINER } from '../consts/constApp';

// import { NotFound, NotAuth } from './exception'
// import { checkAuth } from './auth'

// https://legacy.reactjs.org/docs/code-splitting.html#route-based-code-splitting
// // home
// const Dashboard = lazy(() => import('@/pages/dashboard'))
// // table
// const TablePro = lazy(() => import('@/pages/table/table-pro'))
// const TableAntd = lazy(() => import('@/pages/table/table-antd'))
// const TableAhook = lazy(() => import('@/pages/table/table-ahook'))
// // playground
// const Playground = lazy(() => import('@/pages/playground'))
// // product
// const Phone = lazy(() => import('@/pages/product/phone'))
// const Gold = lazy(() => import('@/pages/product/luxury/gold'))

const menuRoutes: MenuRoute[] = [
  {
    path: '/',
    element: <Navigate replace to="/home" />,
  },
  {
    path: '/',
    loader: checkAuth,
    element: <AdminLayout />, // layout应该不需要lazyload 后续考虑SSR?
    children: [
      {
        name: 'Dashboard',
        path: '/home',
        icon: <DashboardOutlined />,
        element: <HomePage />,
      },
      { type: 'divider' } as any,

      {
        type: 'group', // Must have
        name: 'Operation',
        children: [],
      },
      {
        name: 'Tags',
        path: '/tags',
        icon: <TagFilled />,
        element: <TagsPage />,
        roles: [ROLE_OPERATOR, ROLE_ADMIN, ROLE_POWER_OPERATOR],
      },
      {
        name: 'Feedback',
        path: '/data-management-feedback',
        icon: <RedEnvelopeFilled />,
        element: <FeedbackPage />,
        roles: [ROLE_OPERATOR, ROLE_ADMIN, ROLE_POWER_OPERATOR],
      },
      {
        name: 'Chat History',
        path: '/history',
        icon: <HistoryOutlined />,
        element: <ChatHistoryPage />,
        roles: [ROLE_OPERATOR, ROLE_ADMIN, ROLE_POWER_OPERATOR],
      },

      {
        type: 'group', // Must have
        name: 'Data Management',
        children: [],
      },
      {
        name: 'Train Data',
        path: '/bot-instance-train-data',
        icon: <WechatOutlined />,
        element: <BotInstanceTrainDataPage />,
        roles: [ROLE_TRAINER, ROLE_ADMIN, ROLE_POWER_OPERATOR],
      },
      {
        name: 'Vector Data',
        path: '/data-management-vector-data',
        icon: <BarChartOutlined />,
        element: <VectorDataListPage />,
        roles: [ROLE_TRAINER, ROLE_ADMIN, ROLE_POWER_OPERATOR],
      },

      {
        type: 'group', // Must have
        name: 'Bot Management',
        children: [],
      },
      {
        name: 'Bots',
        path: '/bot-instance-list',
        icon: <OrderedListOutlined />,
        element: <BotInstanceListPage />,
        roles: [ ROLE_ADMIN],
      },
      {
        name: 'LLM',
        path: '/bot-instance-llm',
        icon: <BoldOutlined />,
        element: <BotInstanceLLMPage />,
        roles: [ ROLE_ADMIN],
      },
      {
        name: 'Profile',
        path: '/bot-instance-profile',
        icon: <ProfileFilled />,
        element: <BotInstanceProfilePage />,
        roles: [ ROLE_ADMIN],
      },
      {
        name: 'Widget',
        path: '/bot-instance-widget',
        icon: <WindowsFilled />,
        element: <BotInstanceWidgetPage />,
        roles: [ ROLE_ADMIN],
      },

      {
        type: 'group', // Must have
        name: 'System',
        children: [],
      },
      {
        name: 'Api keys',
        path: '/api-keys',
        icon: <KeyOutlined />,
        element: <ApiKeyPage />,
        roles: [ ROLE_ADMIN],
      },
      {
        name: 'User',
        path: '/users',
        icon: <UsergroupAddOutlined />,
        element: <UserManagementPage />,
        roles: [ ROLE_ADMIN],
      },
      
      
      

      // {
      //   name: 'General Setting',
      //   path: '/general-setting',
      //   icon: <SettingOutlined />,
      // },
      // {
      //   name: 'Manage Models',
      //   path: '/manage-models',
      //   icon: <PieChartOutlined />,
      // },
      // {
      //   name: 'Role',
      //   path: '/roles',
      //   icon: <RocketFilled />,
      //   element: <BotInstanceTrainDataPage />,
      // },
      // {
      //   name: 'Rule',
      //   path: '/rules',
      //   icon: <ForkOutlined />,
      //   element: <BotInstanceTrainDataPage />,
      // },

      // {
      //   name: 'Table',
      //   path: '/table',
      //   icon: <TableOutlined />,
      //   children: [
      //     {
      //       name: 'table-pro',
      //       path: '/table/table-pro',
      //       // element: <TablePro />
      //     },
      //     {
      //       name: 'table-antd',
      //       path: '/table/table-antd',
      //       // element: <TableA />
      //     },
      //     {
      //       name: 'table-ahook',
      //       path: '/table/table-ahook',
      //       // element: <TableAhook />
      //     },
      //   ],
      // },
      // {
      //   name: 'Playground',
      //   path: '/playground',
      //   icon: <MailOutlined />,
      //   children: [
      //     {
      //       name: 'playground 2',
      //       path: '/playground/2',
      //       // index: true,
      //       // element: <Playground />
      //     },
      //   ],
      // },

      // {
      //   type: 'group', // Must have
      //   name: 'Customization',
      //   children: [],
      // },
      // {
      //   name: 'product',
      //   path: '/product',
      //   icon: <AppstoreOutlined />,
      //   children: [
      //     {
      //       index: true,
      //       name: 'Phone',
      //       auth: false,
      //       path: '/product/phone',
      //       // element: <Phone />
      //     },
      //     {
      //       name: 'Scp',
      //       path: '/product/scp',
      //       children: [
      //         {
      //           name: 'Gold',
      //           path: '/product/scp/gold',
      //           // element: <Gold />
      //         },
      //       ],
      //     },
      //   ],
      // },
    ],
  },
  {
    path: '/login',
    loader: checkIsNotAuth,
    element: <LoginPage />,
  },
  {
    path: '/auth/redirect',
    // loader: checkIsNotAuth,
    element: <LoginCallback />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
];

// extract MenuItems for antd Menu
// extract breadcrumbNameMap for antd Breadcrumb
const extractMenuItems = (menuRoutes: MenuRoute[] = []) => {
  const breadcrumbNameMap: BreadcrumbMap<MenuRoute> = {};

  const recurExtractMenuItems = (menuRoutes: MenuRoute[], menuItems: MenuItem[]) => {
    if (menuRoutes?.length) {
      menuRoutes.forEach((item: MenuRoute) => {
        const { name, path, icon, hideInMenu, children } = item;
        breadcrumbNameMap[path] = name as string;
        if (!hideInMenu) {
          if (item.type == 'divider') {
            menuItems.push({
              type: item.type,
            } as any);
          } else if (item.type == 'group') {
            menuItems.push({
              type: item.type,
              label: item.name,
            } as any);
          } else {
            menuItems.push({
              roles: item.roles || undefined,
              key: path,
              icon: icon,
              label: children?.length ? name : <Link to={path}>{name}</Link>,
              ...(children?.length
                ? {
                    children: recurExtractMenuItems(children, []),
                  }
                : {}),
            });
          }
        }
      });
    }
    return menuItems;
  };
  const menuItems = recurExtractMenuItems(menuRoutes, []);
  return { menuItems, breadcrumbNameMap };
};

// extract routes for react-router-dom6
const extractRoutes = (menuRoutes: MenuRoute[]) => {
  const recurExtractRoutes = (menuRoutes: MenuRoute[], routes: RoutesType[]) => {
    if (menuRoutes?.length) {
      menuRoutes.forEach((item: MenuRoute) => {
        const { path, auth, element, loader, children } = item;
        routes.push({
          // index,
          loader,
          path,
          element: auth !== false ? element : <ForbiddenPage />,
          ...(children?.length
            ? {
                children: recurExtractRoutes(children, []),
              }
            : {}),
        });
      });
    }
    return routes;
  };
  return recurExtractRoutes(menuRoutes, []);
};

const { menuItems, breadcrumbNameMap } = extractMenuItems(menuRoutes[1]?.children);
// console.log('menuItems', menuItems, 'breadcrumbNameMap', breadcrumbNameMap);
const routes = extractRoutes(menuRoutes);
// console.log('routes', routes);

export { routes, menuItems, breadcrumbNameMap };
