/* eslint-disable */
import { useState, CSSProperties, useEffect } from 'react';
import { useNavigate, useLoaderData, Navigate, useSearchParams, Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { DeltaStatic } from 'quill';
import {
  Avatar,
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Drawer,
  Dropdown,
  DropDownProps,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Progress,
  Result,
  Row,
  Select,
  Space,
  Switch,
  Table,
  TableProps,
  Tabs,
  Tag,
  Tooltip,
  Typography,
  Upload,
  UploadProps,
  DatePicker,
  Rate,
} from 'antd';
import {
  AlipayOutlined,
  AppleOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
  DeleteFilled,
  DownOutlined,
  EditFilled,
  EditOutlined,
  FacebookOutlined,
  FileExcelFilled,
  FilePdfFilled,
  FileTextFilled,
  FileUnknownFilled,
  FileWordFilled,
  GoogleOutlined,
  HeatMapOutlined,
  LockOutlined,
  MessageOutlined,
  MobileOutlined,
  PlayCircleFilled,
  PlusCircleFilled,
  PlusCircleOutlined,
  PlusOutlined,
  ProfileFilled,
  QuestionCircleFilled,
  ReloadOutlined,
  RetweetOutlined,
  SearchOutlined,
  TaobaoOutlined,
  UploadOutlined,
  UserOutlined,
  WeiboOutlined,
} from '@ant-design/icons';
import { LoginForm, ProFormCaptcha, ProFormCheckbox, ProFormText } from '@ant-design/pro-components';
import { store, useAppDispatch, useAppSelector } from '../../store';
import { selectAuthState, loginByUserPassThunk, getUserInfoThunkAction } from '../../reduxs/authSlice';
import { selectLanuageState, selectThemeState } from '../../reduxs/themeSlice';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { loginGoogleApi } from '../../apis/authApi';
import Loading, { LoadingComponent } from '../../components/loading';
import Paragraph from 'antd/lib/skeleton/Paragraph';
import { GPT_MODEL_3_5, GPT_MODEL_3_5_TURBO, GPT_MODEL_4, GPT_MODEL_4_TURBO, LANGUAGES, RAG_STRATEGY, ROLE_ADMIN, ROLE_USER } from '../../consts/constApp';
import { formatBytes, getCurrentBot, keyToTitle, randomTagClass } from '../../utils/appHelper';
import {
  addADUserApi,
  getAllBotApi,
  getBotByIdApi,
  getListAdUserApi,
  getListChatHistoryApi,
  getListTags,
  getListUserApi,
  getProviderApi,
  getUserByIdApi,
  listDocumentApi,
  removeDocumentApi,
  retrainVectorDataApi,
  updateBotApi,
  updateUserApi,
  uploadTrainDocument,
} from '../../apis/appApi';
import { MenuProps } from 'antd/lib';
import { BotDataType } from '../../types/appTypes';
import CurrentBotComponent from '../../components/currentBot/currentBotComponent';
import { selectAppState, setCurrentBotReduxAction } from '../../reduxs/appSlice';
import BotCreateComponent from '../../components/botCreate/botCreatecomponent';
const { Search } = Input;
const mapTagClass:any = {
  'others' : randomTagClass('default'),
  'Others' : randomTagClass('default'),

  'dental': randomTagClass(1),
  'Dental': randomTagClass(1),

  'thuế': randomTagClass(2),
  'Thuế': randomTagClass(2),
  'tax': randomTagClass(2),
  'Tax': randomTagClass(2),
  
  'policy': randomTagClass(3),
  'Policy': randomTagClass(3),

  'health': randomTagClass(5),
  'Health': randomTagClass(5),
};
const ChatHistoryPage = () => {
  const { t, i18n } = useTranslation();
  const userInfo = useLoaderData();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuthState);
  const appState = useAppSelector(selectAppState);
  const themeState = useAppSelector(selectThemeState);
  const lang = useAppSelector(selectLanuageState);
  const [searchParams, setSearchParams] = useSearchParams();
  const [botItems, setBotItems] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpenModalCreate, setisOpenModalCreate] = useState(false);
  const [isOpenAddADUser, setisOpenAddADUser] = useState(false);
  const [documents, setDocuments] = useState<any>([]);
  const [chatConversation, setChatConversation] = useState<any>([]);
  const [currentChatConversation, setCurrentChatConversation] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [total, setTotal] = useState(0);
  const [searchCondition, setSearchCondition] = useState({
    from: '',
    to: '',
    tags: '',
    botId: '',
    tagType: 'feedback',
  } as any);

  const [showModalEditMessage, setshowModalEditMessage] = useState(false);
  const [currentMessage, setcurrentMessage] = useState(null);
  const [activeCollapseIndex, setActiveCollapseIndex] = useState(1);

  const loadChatConversation_ = async ({ q = null, page: pageParam, limit: limitParam, searchConditionParam }: any) => {
    const { from, to, tags, botId } = searchConditionParam || {};
    setLoading(true);
    try {
      let result = await getListChatHistoryApi({
        page: pageParam || page,
        limit: limitParam || limit,
        search: {
          ...searchCondition,
          ...(searchConditionParam || {}),
          tagType: 'feedback',
        },
      });
      let items = result.items.map((r: any, idx: number) => {
        return {
          ...r,
          key: r.id || idx,
        };
      });
      setChatConversation(items.map((r:any)=>{
        if(r?.tags && r?.tags?.length > 0){
          for(let tag of r?.tags){
            let k = String(tag).toLowerCase();
            if(!mapTagClass[k]){
              mapTagClass[k] = randomTagClass();
            }
          }
        }
        return r;
      }));
      setTotal(result.total);
      if (!currentChatConversation) {
        setCurrentChatConversation(items[0] || null);
      }
    } catch (error) {
      console.error(error);
      message.error(((error as any) || {})['message'] || JSON.stringify(error));
    }
    setLoading(false);
  };

  const initFn: any = async () => {
    const [_, result, tagsData] = await Promise.all([loadChatConversation_({}), getAllBotApi(), getListTags({})]);
    setBotItems(result.items);
    setAllTags(tagsData.items);
    console.log(`all tags`, tagsData.items);
  };

  useEffect(() => {
    initFn();
  }, []);
  const labelBotName = (botId: string) => {
    let item: any = botItems.find((r) => (r as any).id === botId);
    return item?.name || item?.title;
  };
  const labelBotDesciption = (botId: string) => {
    let item: any = botItems.find((r) => (r as any).id === botId);
    return item?.description;
  };
  const avatarUrlBot = (botId: string) => {
    let item: any = botItems.find((r) => (r as any).id === botId);
    return item?.avatar;
  };
  const onClickSetCurrConversation = (item: any, idx: number) => {
    setCurrentChatConversation(item);
  };
  let submitRetrainMessageFunction: any;
  const onShowRetrainMessage = (message: any, idx: any, preHistory: any) => {
    setcurrentMessage(message);
    setshowModalEditMessage(true);
    setActiveCollapseIndex(1);
    setcurrentMessage({
      ...(message as any),
      refEmbeddings: message.refEmbeddings,
      messageId: message?.id,
      question: preHistory?.content,
      oldAnswer: message?.content,
      answer: '',
    });
    console.log(`refEmbeddings`, message.refEmbeddings);
  };
  const renderCollapseItemPannel = (text: string, panelIdx: number) => {
    return (
      <div>
        <ReactQuill
          key={panelIdx}
          theme="snow"
          defaultValue={
            {
              ops: [
                {
                  insert: String(text || '').toString(),
                },
              ],
            } as DeltaStatic
          }
          onChange={(v: string, delta: DeltaStatic, source: any, editor: any) => {
            let refEmbeddings = (currentMessage as any)?.refEmbeddings || [];
            let textContent = editor?.getText() || v;
            refEmbeddings[panelIdx].text = textContent;
            setcurrentMessage({
              ...(currentMessage as any),
              refEmbeddings,
            });
          }}
        />
      </div>
    );
  };
  const onSubmitRetrainLocalData = async (refEmbeddingsItem: any, localDataIdx = 0) => {
    let botId = (currentChatConversation as any)?.botId;
    let messageId = (currentMessage as any)?.messageId;
    let embeddingId = refEmbeddingsItem.id;
    let text = refEmbeddingsItem.text;
    setLoading(true);
    try {
      let paramRetrain = { messageId, botId, embeddingId, text };
      await retrainVectorDataApi({ ...paramRetrain });
      message.success(`Retrain success`);
    } catch (error) {
      console.error(error);
      message.error(((error as any) || {})['message'] || JSON.stringify(error));
    }
    setLoading(false);
  };
  const renderItemsRefEmbeddings = (): any => {
    let refEmbeddings = (currentMessage as any)?.refEmbeddings || [];
    let items = refEmbeddings.map((item: any, idx: number) => {
      let idxxx = idx + 1;
      return {
        // collapsible:'icon',
        key: idxxx,
        extra: String(activeCollapseIndex).toString() === String(idxxx).toString() && (
          <Button
            danger
            type="default"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              Modal.confirm({
                title: 'Confirm',
                content: 'Are you sure want to retrain this data?',
                onOk: () => {
                  onSubmitRetrainLocalData(item, idx);
                },
              });
            }}
          >
            Retrain
          </Button>
        ),
        label: (
          <div>
            <Row>
              <Col span={12}>
                <Typography.Paragraph ellipsis={{ rows: 1 }} style={{ width: 400, fontWeight: 'bold' }}>
                  {item.text}
                </Typography.Paragraph>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}></Col>
            </Row>
          </div>
        ),
        children: renderCollapseItemPannel(item.text, idx),
      } as any;
    });
    return items;
  };
  // console.log(`mapTagClass`, mapTagClass);
  return (
    <div className="chat-history">
      {loading && <LoadingComponent />}
      <Row>
        <Col span={7} className="chat-list">
          {/* <Search placeholder="Search text ..." className="search-block" /> */}
          <div className="filter-block mb-1">
            <Row>
              <Col span={24}>
                <DatePicker.RangePicker
                  className="range-picker"
                  onFocus={(_, info) => {
                    console.log('Focus:', info.range);
                  }}
                  onBlur={(_, info) => {
                    console.log('Blur:', info.range);
                  }}
                  onChange={(dates, dateStrings) => {
                    console.log(`on change range picker`, dateStrings);
                    setSearchCondition({
                      ...searchCondition,
                      from: dateStrings[0] || '',
                      to: dateStrings[1] || '',
                    });
                    loadChatConversation_({
                      searchConditionParam: {
                        ...searchCondition,
                        from: dateStrings[0] || '',
                        to: dateStrings[1] || '',
                      },
                    });
                  }}
                />
              </Col>

              <Col span={24}>
                <Select
                  className="select-tags"
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Select tags"
                  defaultValue={[]}
                  onChange={(value: string[]) => {
                    console.log(`selected tags ${value}`);
                    let tmpSearchCondition = { ...searchCondition };
                    tmpSearchCondition.tags = value.join(',');
                    setSearchCondition(tmpSearchCondition);
                    console.log(`load history by tags`, tmpSearchCondition);
                    loadChatConversation_({
                      searchConditionParam: {
                        ...tmpSearchCondition,
                      },
                    });
                  }}
                  options={allTags.map((r: any) => {
                    return {
                      label: r.name,
                      value: r.code,
                    };
                  })}
                />
              </Col>
              <Col span={24}>
                <Select
                  className="select-bot"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Select bot"
                  defaultValue={null}
                  onChange={(value: string[]) => {
                    console.log(`selected bot ${value}`);
                    let tmpSearchCondition = { ...searchCondition };
                    tmpSearchCondition.botId = value;
                    setSearchCondition(tmpSearchCondition);
                    console.log(`load history by bot `, tmpSearchCondition);
                    loadChatConversation_({
                      searchConditionParam: {
                        ...tmpSearchCondition,
                      },
                    });
                  }}
                  options={botItems.map((r: any) => {
                    return {
                      label: r.name || r.code,
                      value: r.id,
                    };
                  })}
                />
              </Col>
            </Row>
          </div>

          <div className="conversations">
            {chatConversation.map((item: any, idx: number) => {
              return (
                <div
                  className={`conversations-item ${item.id == currentChatConversation.id ? 'active' : ''}`}
                  key={idx}
                  onClick={(e) => {
                    onClickSetCurrConversation(item, idx);
                  }}
                >
                  <Space>
                    <Avatar className="avatar" src={item.profileAvatar} />
                    <Space.Compact direction="vertical" className="profile">
                      <span className="username">{item.profileName || 'Anonymous'}</span>
                      <span className="first-msg">{item.title || (item.histories && item.histories[0]?.content) || 'No message'}</span>
                    </Space.Compact>
                  </Space>

                  <span className="time-created">{moment(item.createdAt).format('DD-MM HH:mm') || 'Just now'}</span>
                  <span className="rating">
                    {item.star !== null && item.star >= 0 && <Rate value={item.star} onChange={e=>null} />}
                  </span>
                  <span className='tags'>
                    {(item.tags || []).map((tag:any, idx:any)=>{
                      if(idx > 3) return null;
                      return <Tag key={idx} color={mapTagClass[String(tag).toLowerCase()] || randomTagClass()} >{tag}</Tag>
                    })}
                  </span>
                </div>
              );
            })}
          </div>
          <div className="paging-block">
            <Pagination
              showSizeChanger={false}
              current={page}
              pageSize={limit}
              total={total}
              onChange={(pageCb, pageSizeCb) => {
                console.log(`change page`, pageCb, pageSizeCb);
                if (limit !== pageSizeCb) {
                  setLimit(pageSizeCb);
                }
                setPage(pageCb);
                loadChatConversation_({ page: pageCb, limit: pageSizeCb || limit });
                // loadFeedbackData({ page: pageCb, limit: pageSizeCb || limit });
              }}
            />
          </div>
        </Col>
        <Col span={17} className="chat-block">
          <div className="chat-histories">
            <div className="chat-histories-header">
              <Space>
                <Avatar src={avatarUrlBot(currentChatConversation?.botId)} />
                <Space.Compact direction="vertical">
                  <span className="bot-name">{labelBotName(currentChatConversation?.botId)}</span>
                  <span className="bot-description">{labelBotDesciption(currentChatConversation?.botId)}</span>
                </Space.Compact>
              </Space>
            </div>
            <div className="chat-histories-messages">
              {(currentChatConversation?.histories || []).map((history: any, idx: number) => {
                let preHistory = currentChatConversation?.histories[idx - 1];
                return (
                  <div key={idx} className={`history-message ` + (history.role === 'user' ? 'question-message' : 'chat-bot-message')}>
                    <div className="history-message-content">
                      <span className="message">{history.content}</span>
                      <Avatar className="avatar" src={history.role === 'user' ? '/images/user2.png' : avatarUrlBot(currentChatConversation?.botId)}></Avatar>
                      <span className="timeCreated">{moment(history.createdAt).format('DD-MM HH:mm A')}</span>
                      <span className="retrain-message">
                        <Tooltip title="Retrain">
                          <RetweetOutlined
                            onClick={(e) => {
                              onShowRetrainMessage(history, idx, preHistory);
                            }}
                          />
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Col>
      </Row>

      {/* <div className="list">
          <Table columns={columns} dataSource={users} />
          </div> */}
      {showModalEditMessage && currentMessage && (
        <Modal
          width={'90%'}
          open={true}
          title={'Edit message'}
          // footer={null}
          onCancel={(e) => {
            setshowModalEditMessage(false);
            setcurrentMessage(null);
          }}
          okText={'Retrain'}
          okButtonProps={{
            disabled: loading,
            style: { backgroundColor: 'red' },
          }}
          onOk={async (e) => {
            // await submitSaveCurrentVectorData();
            (submitRetrainMessageFunction as any)();
          }}
          // style={{background:'#fafafa'}}
          footer={null}
        >
          <Divider />
          <div className="text-editor">
            <div className="mr-bottom-5">
              Question:
              <Input.TextArea disabled value={(currentMessage as any)?.question} />
            </div>

            <div className="mr-bottom-5">
              Answer:
              <Input.TextArea rows={3} disabled value={(currentMessage as any)?.oldAnswer} />
            </div>
            <div>
              <Typography.Text>Reference Train Data</Typography.Text>
            </div>
            <Collapse
              items={renderItemsRefEmbeddings()}
              accordion={true}
              defaultActiveKey={['1']}
              onChange={(key) => {
                console.log(key);
                if (key && (key[0] as any)) {
                  setActiveCollapseIndex(key && (key[0] as any));
                } else {
                  setActiveCollapseIndex(0);
                }
              }}
            ></Collapse>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ChatHistoryPage;
