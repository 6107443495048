import { useState, CSSProperties, useEffect, useRef } from 'react';
import { useNavigate, useLoaderData, Navigate, useSearchParams, Link } from 'react-router-dom';
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  DropDownProps,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Progress,
  Result,
  Row,
  Select,
  Space,
  Switch,
  Table,
  TableProps,
  Tabs,
  Tag,
  Tooltip,
  Upload,
  UploadProps,
  Typography,
  Badge,
  Popconfirm,
  Rate,
  Flex,
  DatePicker,
} from 'antd';
import { CheckOutlined, DeleteFilled, EditOutlined, EyeOutlined, PlusCircleFilled, SearchOutlined, StopOutlined } from '@ant-design/icons';
import { LoginForm, ProFormCaptcha, ProFormCheckbox, ProFormText } from '@ant-design/pro-components';
import { store, useAppDispatch, useAppSelector } from '../../store';
import { selectAuthState, loginByUserPassThunk, getUserInfoThunkAction } from '../../reduxs/authSlice';
import { selectLanuageState, selectThemeState } from '../../reduxs/themeSlice';
import { useTranslation } from 'react-i18next';
import { loginGoogleApi } from '../../apis/authApi';
import Loading, { LoadingComponent } from '../../components/loading';
import { FeedbackStatusEnum, GPT_MODEL_3_5, GPT_MODEL_3_5_TURBO, GPT_MODEL_4, GPT_MODEL_4_TURBO, LANGUAGES, RAG_STRATEGY } from '../../consts/constApp';
import { formatBytes, getCurrentBot, keyToTitle } from '../../utils/appHelper';
import {
  addVectorDataApi,
  feedbackListApi,
  feedbackUpdateApi,
  getAllBotApi,
  getBotByIdApi,
  getProviderApi,
  listDocumentApi,
  removeDocumentApi,
  retrainVectorDataApi,
  searchVectorDataApi,
  updateBotApi,
  uploadTrainDocument,
} from '../../apis/appApi';
import { MenuProps } from 'antd/lib';
import { BotDataType } from '../../types/appTypes';
import CurrentBotComponent from '../../components/currentBot/currentBotComponent';
import { selectAppState, setCurrentBotReduxAction } from '../../reduxs/appSlice';
import BotCreateComponent from '../../components/botCreate/botCreatecomponent';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DeltaStatic } from 'quill';
import Search from 'antd/lib/input/Search';

const Paragraph = Typography.Paragraph;
const styleBreakline: any = {
  whiteSpace: 'pre-line',
  overflowWrap: 'break-word',
  wordBreak: 'break-word',
};
const FeedbackPage = () => {
  let reactEditor = useRef(null);
  const { t, i18n } = useTranslation();
  const userInfo = useLoaderData();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuthState);
  const appState = useAppSelector(selectAppState);
  const themeState = useAppSelector(selectThemeState);
  const lang = useAppSelector(selectLanuageState);
  const [filterParams, setFilterParams] = useState({
    // status: '',
    star: '',
  } as any);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [vectorDataItems, setVectorDataItems] = useState<any>([]);

  const [showModalView, setShowModalView] = useState<any>(false);
  const [showModalEdit, setShowModalEdit] = useState<any>(false);
  const [showModalAdd, setShowModalAdd] = useState<any>(false);
  const [currentFeedbackData, setCurrentFeedbackData] = useState(null as any);

  const [botItems, setBotItems] = useState<any>([]);
  const [botData, setBotData]: [BotDataType, any] = useState((getCurrentBot() as BotDataType) || {});

  const columns: TableProps<any>['columns'] = [
    
    // {
    //   title: keyToTitle('question'),
    //   dataIndex: 'question',
    //   key: 'question',
    //   render: (question, record) => (
    //     <>
    //       <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: 'more' }} style={{ ...styleBreakline }}>
    //         {String(record.question).toString()}
    //       </Paragraph>
    //     </>
    //   ),
    // },
    // {
    //   title: keyToTitle('answer'),
    //   dataIndex: 'answer',
    //   key: 'answer',
    //   render: (answer, record) => (
    //     <>
    //       <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: 'more' }} style={{ ...styleBreakline }}>
    //         {String(record.answer).toString()}
    //       </Paragraph>
    //     </>
    //   ),
    // },
    {
      title: keyToTitle('summary'),
      dataIndex: 'summary',
      key: 'summary',
      render: (summary, record) => (
        <>
          <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: 'more' }} style={{ ...styleBreakline }}>
            {String(record.summary || '').toString()}
          </Paragraph>
        </>
      ),
    },
    {
      title: keyToTitle('message'),
      dataIndex: 'message',
      key: 'message',
      render: (answer, record) => (
        <>
          <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: 'more' }} style={{ ...styleBreakline }}>
            {String(record.message || '').toString()}
          </Paragraph>
        </>
      ),
    },
    {
      title: keyToTitle('star'),
      dataIndex: 'star',
      key: 'star',
      width:130,
      render: (answer, record) => {
        let star = record.star || 5;
        return (
          <>
            {[1, 2, 3, 4, 5].map((i, idx) => {
              return <img style={{ width: '18px', height: '18px' }} key={idx} src={i <= star ? '/images/icons8-star-select-50.png' : '/images/icons8-star-50.png'} />;
            })}
          </>
        );
      },
    },
    {
      title: keyToTitle('createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width:120,
      render: (answer, record) => (
        <>
          <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: 'more' }} style={{ ...styleBreakline }}>
            {String(record.createdAt).substring(0, 10)}
          </Paragraph>
        </>
      ),
    },
    {
      title: keyToTitle('createdBy'),
      dataIndex: 'createdBy',
      key: 'createdBy',
      width:120,
      render: (answer, record) => (
        <>
          <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: 'more' }} style={{ ...styleBreakline }}>
            {String(record.createdBy || '').toString()}
          </Paragraph>
        </>
      ),
    },
    // {
    //   title: 'Status',
    //   key: 'feedbackStatus',
    //   render: (_, record) => {
    //     let feedbackStatus = record.feedbackStatus;
    //     let color = 'red';
    //     if (feedbackStatus == FeedbackStatusEnum.deny) {
    //       color = 'red';
    //     }
    //     if (feedbackStatus == FeedbackStatusEnum.request) {
    //       color = 'orange';
    //     }
    //     if (feedbackStatus == FeedbackStatusEnum.approve) {
    //       color = 'green';
    //     }
    //     return <Tag color={color}>{feedbackStatus}</Tag>;
    //   },
    // },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        let feedbackStatus = record.feedbackStatus;
        let isShowApprove = [FeedbackStatusEnum.request].includes(feedbackStatus);
        let isShowReject = [FeedbackStatusEnum.request].includes(feedbackStatus);
        let isShowEdit = [FeedbackStatusEnum.approve, FeedbackStatusEnum.request].includes(feedbackStatus);

        return (
          <Space size="middle">
            {
              // isShowEdit && (
              //   <Tooltip title={'Edit'}>
              //     <EditOutlined
              //       onClick={(e) => {
              //         setShowModalEdit(true);
              //         setCurrentFeedbackData(record);
              //       }}
              //       style={{ color: 'orange' }}
              //     />
              //   </Tooltip>
              // )
            }

            {
              // isShowApprove && (
              //   <Tooltip title={'Approve'}>
              //     <Popconfirm
              //       title="Approve"
              //       description="Are you sure to approve this feedback?"
              //       onConfirm={(e) => {
              //         setCurrentFeedbackData(record);
              //         submitFeedback({
              //           ...record,
              //           feedbackStatus: FeedbackStatusEnum.approve,
              //           action: 'approve',
              //         });
              //       }}
              //       onCancel={(e) => {}}
              //       okText="Yes"
              //       cancelText="No"
              //     >
              //       <CheckOutlined
              //         onClick={(e) => {
              //           // setCurrentFeedbackData(record);
              //           // setShowModalView(true);
              //         }}
              //         style={{ color: 'green' }}
              //       />
              //     </Popconfirm>
              //   </Tooltip>
              // )
            }

            {
              // isShowReject && (
              //   <Tooltip title={'Reject'}>
              //     <Popconfirm
              //       title="Reject"
              //       description="Are you sure to rejet this feedback?"
              //       onConfirm={(e) => {
              //         setCurrentFeedbackData(record);
              //         submitFeedback({
              //           ...record,
              //           feedbackStatus: FeedbackStatusEnum.deny,
              //           action: 'reject',
              //         });
              //       }}
              //       onCancel={(e) => {}}
              //       okText="Yes"
              //       cancelText="No"
              //     >
              //       <StopOutlined style={{ color: 'red' }} />
              //     </Popconfirm>
              //   </Tooltip>
              // )
            }
            <Tooltip title={'Delete'}>
              <Popconfirm
                title="Delete"
                description="Are you sure to Delete this feedback?"
                onConfirm={(e) => {
                  submitFeedback({
                    ...record,
                    action: 'delete',
                  });
                }}
                onCancel={(e) => {}}
                okText="Yes"
                cancelText="No"
              >
                <DeleteFilled style={{ color: 'red' }} />
              </Popconfirm>
            </Tooltip>
          </Space>
        );
      },
    },
  ];
  const loadAllBotItems = async () => {
    const setBotDataCustom = (data: any) => {
      setBotData(data);
      dispatch(setCurrentBotReduxAction(data));
    };
    setLoading(true);
    const [result] = await Promise.all([getAllBotApi()]);
    let allBotItems = result.items;
    setBotItems(allBotItems as any);
    if (!botData || !botData.id) {
      setBotDataCustom(allBotItems[0]);
    } else if (botData) {
      if (botData?.id !== appState.currentBotInstance?.id) {
        setBotDataCustom(appState.currentBotInstance);
      }
    }
    setLoading(false);
  };
  const loadFeedbackData = async (paramsInput: any = null) => {
    const { page: pageParam, limit: limitParam, keyword, status, params } = paramsInput || {};
    setLoading(true);
    const [result] = await Promise.all([
      feedbackListApi({
        // botId: botData?.id,
        q: keyword === '' ? '' : keyword || searchValue,
        page: pageParam || page,
        limit: limitParam || limit,
        // status: status || '',
        params: { ...filterParams, ...params },
      }),
    ]);
    let total = result.total;
    let items = result.items;
    setTotal(total);
    setVectorDataItems(
      items.map((r: any) => {
        return {
          ...r,
          originText: r.text,
        };
      })
    );
    setLoading(false);
  };
  useEffect(() => {
    loadAllBotItems();
    loadFeedbackData({});
  }, []);

  const submitEditFeedback = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await feedbackUpdateApi({
        id: currentFeedbackData?.id,
        payload: {
          ...currentFeedbackData,
        },
      });
      await loadFeedbackData();
      setShowModalEdit(false);
    } catch (error: any) {
      console.error(error);
      message.error(JSON.stringify(error));
    }
    setLoading(false);
  };
  const submitFeedback = async (params: any = null) => {
    const { feedbackStatus, action } = params || {};
    if (loading) return;
    setLoading(true);

    try {
      await feedbackUpdateApi({
        id: params?.id || currentFeedbackData?.id,
        payload: {
          ...(params || currentFeedbackData),
          feedbackStatus: feedbackStatus,
          action,
        },
      });
      if (action == 'approve') {
        message.success(`Approve feedback success`);
      }
      if (action == 'reject') {
        message.success(`Reject feedback success`);
      }
      if (action == 'delete') {
        message.success(`Delete feedback success`);
      }
      await loadFeedbackData();
      setShowModalEdit(false);
    } catch (error: any) {
      console.error(error);
      message.error(JSON.stringify(error));
    }

    setLoading(false);
  };

  return (
    <div className="list-bot-instance">
      <Card
        title={
          'Feedback'
          // <CurrentBotComponent
          //   allBotItems={botItems}
          //   // callback={(selectBot:any) => {
          //   //   if(selectBot)
          //   //   setBotData(selectBot);
          //   // }}
          // />
        }
      >
        {loading && <LoadingComponent />}

        <div className="list">
          <Row style={{ marginBottom: '15px' }}>
            <Col span={6} style={{ textAlign: 'left', marginLeft: '0px' }}>
              <DatePicker.RangePicker
                onChange={(dates, dateStrings) => {
                  setFilterParams({
                    ...filterParams,
                    from: dateStrings[0],
                    to: dateStrings[1],
                  });
                  loadFeedbackData({
                    params: {
                      ...filterParams,
                      from: dateStrings[0],
                      to: dateStrings[1],
                    },
                  });
                }}
              />
            </Col>
            <Col span={4}>
              <Space style={{ display: 'flex', height: '30px' }}>
                <Rate
                  style={{ marginLeft: '10px', marginRight: '10px' }}
                  onChange={(v) => {
                    setFilterParams({
                      ...filterParams,
                      star: v,
                    });
                    loadFeedbackData({
                      params: {
                        ...filterParams,
                        star: v,
                      },
                    });
                  }}
                  value={filterParams.star}
                />
              </Space>
            </Col>

            <Col span={6}>
              <Select
                placeholder="Select bot"
                defaultValue={null}
                allowClear={true}
                style={{ width: '96%' }}
                onChange={(value: string) => {
                  console.log(`selected ${value}`);
                  setFilterParams({
                    ...filterParams,
                    botId: value,
                  });
                  loadFeedbackData({
                    params: {
                      ...filterParams,
                      botId: value,
                    },
                  });
                }}
                options={botItems.map((r: any) => {
                  return {
                    value: r.id,
                    label: r.name,
                  };
                })}
              />
            </Col>
            <Col span={6}>
              <Search
                placeholder="Keyword ..."
                loading={loading}
                allowClear={false}
                onSearch={async (value, _e, info) => {
                  console.log(`on change search`, value);
                  setSearchValue(value);
                  await loadFeedbackData({
                    keyword: value,
                    params: {
                      ...filterParams,
                    },
                  });
                }}
                enterButton
              />
            </Col>
          </Row>

          <Table
            columns={columns}
            dataSource={vectorDataItems.map((r: any, idx: number) => {
              return {
                ...r,
                key: r.idx + String(idx),
              };
            })}
            pagination={false}
            style={{ marginBottom: '15px' }}
          />
          <Pagination
            current={page}
            pageSize={limit}
            total={total}
            onChange={(pageCb, pageSizeCb) => {
              console.log(`change page`, pageCb, pageSizeCb);
              if (limit !== pageSizeCb) {
                setLimit(pageSizeCb);
              }
              setPage(pageCb);
              loadFeedbackData({ page: pageCb, limit: pageSizeCb || limit });
            }}
          />
        </div>

        {/* {showModalEdit && currentFeedbackData && (
          <Modal
            width={'90%'}
            open={showModalEdit}
            title={'Edit vector data'}
            // footer={null}
            onCancel={(e) => {
              setShowModalEdit(false);
              setCurrentFeedbackData(null);
            }}
            okText={'Retrain'}
            okButtonProps={{
              disabled: loading,
              style: { backgroundColor: 'red' },
            }}
            onOk={async (e) => {
              await submitSaveCurrentVectorData();
            }}
            // style={{background:'#fafafa'}}
          >
            <Divider />
            <div className="text-editor">
              {loading && <LoadingComponent />}
              <div>
                <Typography.Text>Editor</Typography.Text>
              </div>
              {currentFeedbackData && (
                <ReactQuill
                  ref={reactEditor}
                  theme="snow"
                  defaultValue={
                    {
                      ops: [
                        {
                          insert: String(currentFeedbackData.text).toString(),
                        },
                      ],
                    } as DeltaStatic
                  }
                  // value={{
                  //   ops: [{
                  //     "insert": currentFeedbackData.text
                  //   }]
                  // } as DeltaStatic}
                  // style={styleBreakline}
                  onChange={(v: string, delta: DeltaStatic, source: any, editor: any) => {
                    let textContent = editor?.getText() || v;
                    // console.log(`onchange data`, v);
                    // console.log(`onchange data delta=`, delta);
                    // console.log(`onchange data source=`, source);
                    // console.log(`onchange data getText=`, textContent);
                    // console.log(`onchange data editor=`, editor, `\n\n\neditor.getText()`, editor.getText(),
                    // `\n\n\n editor.getContents(): `,
                    // editor.getContents(), `\n\n\n editor.getHTML()`,editor.getHTML());
                    setCurrentFeedbackData({
                      ...currentFeedbackData,
                      text: textContent,
                    });
                  }}
                />
              )}
            </div>
          </Modal>
        )} */}

        {/* {showModalAdd && currentFeedbackData && (
          <Modal
            width={'90%'}
            open={showModalAdd}
            title={'Add vector data'}
            // footer={null}
            onCancel={(e) => {
              setShowModalAdd(false);
              setCurrentFeedbackData(null);
            }}
            okText={'Add vector data'}
            okButtonProps={{
              disabled: loading,
              style: { backgroundColor: 'red' },
            }}
            onOk={async (e) => {
              await submitSaveCurrentVectorData({action:'new'});
            }}
          >
            <Divider />
            <div className="text-editor">
              {loading && <LoadingComponent />}
              <div>
                <Typography.Text>Editor</Typography.Text>
              </div>
              {currentFeedbackData && (
                <ReactQuill
                  ref={reactEditor}
                  theme="snow"
                  defaultValue={
                    {
                      ops: [
                        {
                          insert: ``,
                        },
                      ],
                    } as DeltaStatic
                  }
                  onChange={(v: string, delta: DeltaStatic, source: any, editor: any) => {
                    let textContent = editor?.getText() || v;
                    // console.log(`onchange data getText=`, textContent);
                    setCurrentFeedbackData({
                      ...currentFeedbackData,
                      text: textContent,
                    });
                  }}
                />
              )}
            </div>
          </Modal>
        )} */}
      </Card>

      {showModalEdit && (
        <Modal
          open={true}
          title={'Edit feedback'}
          onOk={(e) => {
            // setShowModalEdit(false);
            submitEditFeedback();
          }}
          onCancel={(e) => {
            setShowModalEdit(false);
          }}
          width={'90%'}
        >
          <Divider />
          <div>
            {[
              { key: 'question', disabled: true },
              { key: 'answer', disabled: true },
              { key: 'message', title: 'Correct Answer' },
            ].map((keyObj, idx) => {
              return (
                <Row key={idx} style={{ marginBottom: '15px' }}>
                  <Col span={24}>
                    <Typography.Text>{keyObj.title || keyToTitle(keyObj.key)}</Typography.Text>
                  </Col>
                  <Col span={24}>
                    {keyObj.key == 'message' && showModalEdit && currentFeedbackData && (
                      <ReactQuill
                        ref={reactEditor}
                        theme="snow"
                        defaultValue={
                          {
                            ops: [
                              {
                                insert: String(currentFeedbackData.message).toString(),
                              },
                            ],
                          } as DeltaStatic
                        }
                        onChange={(v: string, delta: DeltaStatic, source: any, editor: any) => {
                          let textContent = editor?.getText() || v;
                          // console.log(`onchange data getText=`, textContent);
                          setCurrentFeedbackData({
                            ...currentFeedbackData,
                            message: textContent,
                          });
                        }}
                      />
                    )}
                    {keyObj.key !== 'message' && (
                      <Input
                        disabled={keyObj.disabled}
                        onChange={(e) => {
                          setCurrentFeedbackData({
                            ...currentFeedbackData,
                            [keyObj.key]: e.target.value,
                          });
                        }}
                        className="form-control"
                        value={currentFeedbackData?.[keyObj.key]}
                      />
                    )}
                  </Col>
                </Row>
              );
            })}
          </div>
          <Divider />
        </Modal>
      )}
    </div>
  );
};

export default FeedbackPage;
